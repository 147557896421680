<template>
  <div class="page">
    <p class="pageTitle">Данной страницы не найдено.</p>
    <router-link to="/" tag="div" class="backHome">На главную</router-link>
  </div>
</template>

<script>
export default {
  name: "404",
  created() {
    document.title = 'IzvarinLaw - 404';
  }
}
</script>

<style scoped lang="sass">
  .pageTitle
    font-size: 24px
    font-weight: 600
    margin-bottom: 50px

    text-align: center
  .page
    width: 100%
    display: flex
    flex-direction: column
    align-items: center
    padding: 20px
  .backHome
    cursor: pointer
</style>